<template>
    <div class="cert-group">

        <div class="page-header">
            <el-row>
                <el-col :span="24" align="right">
                    <router-link to='/'><el-button type="primary" round>Create New Group</el-button></router-link>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            align="center"
                            prop="group_number"
                            label="Group Number"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="group_name"
                            label="Group Name"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="create_date"
                            label="Create Date">
                        </el-table-column>
                        <el-table-column
                            prop="campus_branch"
                            label="Campus/Branch">
                        </el-table-column>
                        <el-table-column
                            prop="handle_account"
                            label="Handle account">
                        </el-table-column>
                        <el-table-column
                            prop="designs"
                            label="Designs">
                        </el-table-column>
                        <el-table-column
                            prop="approved_certs"
                            label="Approved Certs">
                        </el-table-column>
                        <el-table-column
                            align="right"
                            label="操作"
                            width="190">
                            <template #default="scope">
                                <el-button @click="handleEdie(scope.row)" type="primary" size="small">Edit</el-button>
                                <el-popconfirm
                                @confirm="handleDelete(scope.row, scope.$index)"
                                title="確定要刪除嗎？">
                                    <template #reference>
                                        <el-button type="danger" size="small">Delete</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>


        <el-dialog title="ECert Group Edit" v-model="dialogFormVisible">
            <el-form :model="form">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Group Name" :label-width="formLabelWidth">
                            <el-input v-model="form.group_name" autocomplete="off" placeholder="Enter Group Name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Group Number" :label-width="formLabelWidth">
                            <el-input v-model="form.group_number" autocomplete="off" placeholder="Enter Group Number"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="submitCertGroup()">Save</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

  <script>
    import { ElMessage } from 'element-plus'
    export default {
      name: "cert_group",
      data() {
        return {
          tableData: [{
            group_number: '1',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '2',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '3',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '4',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          },{
            group_number: '5',
            group_name: '2019 - IT524110',
            create_date: 'xx/xx/xxxx ',
            campus_branch: 'Branch A',
            handle_account: 'Admin A',
            designs: '10',
            approved_certs: '100'
          }],
          dialogFormVisible: false,
          formLabelWidth: '120px',
          form: {
            group_name: '',
            group_number: ''
          },
        }
      },
      methods: {
        handleEdie(row) {
            this.dialogFormVisible = true;
            this.form.group_name = row.group_name;
            this.form.group_number = row.group_number;
            console.log(row);
        },
        handleDelete(row,index){
            ElMessage.success({
                message: '刪除成功',
                type: 'success'
            });
            this.tableData.splice(index, 1)
        },
        submitCertGroup(){
            console.log(this.form);
            ElMessage.success({
                message: '修改成功',
                type: 'success'
            });
            this.dialogFormVisible = false;
        }
       }
    }
  </script>
